import './ParticipantsList.scss';

import React, {FC} from 'react';
import {IDriver, IParticipant} from "../../../helpers/types";
import ParticipantItem from "../ParticipantItem/ParticipantItem";
import Placeholder from "../../atoms/Placeholder/Placeholder";
import Pagination from "../../atoms/Pagination/Pagination";
import {applyMultiplierMoney, getDateRange, getMultiplier, getTimeDate} from "../../../helpers/functions";
import Avatar from "../../atoms/Avatar/Avatar";
import InputGradient from "../InputGradient/InputGradient";
import EditIcon from "../../atoms/icons/EditIcon";
import Tag from "../../atoms/Tag/Tag";

interface IParticipantsList{
    numbersList?:any[]
    isCheck?: boolean;
    items: IDriver[];
    onChangePagination?:(value:number)=>void;
    totalCount?:number;
    currentPage?:number;
    perPage?:number;
    pageUrl?:string;
    onCheckSelect?: (name:string,checked:boolean)=>void;
    onSave?: (id:number,values:any)=>void;
    onDelete?: (id:number)=>void
    isDelete?: boolean;
    currentDate?:string;
    edit?: boolean;
}

const ParticipantsList:FC<IParticipantsList> = ({numbersList=[],onSave=()=>{},edit=false,currentDate='yesterday',isDelete=false,onDelete=()=>{},isCheck=true,onCheckSelect=()=>{},items,onChangePagination=()=>{},totalCount=0,perPage=12,currentPage=1,pageUrl=''}) => {
    //onCheckSelect(item.email as string,e)

    const {dateTo,dateFrom} = getDateRange(currentDate);
//${(item.total/100*(1-getMultiplier(item.total/100))).toFixed(2)} ern
    //${(applyMultiplierMoney(item.total/100)-item.cash/100).toFixed(2)}
    return (
        <div className={'participant-list'}>
            <div style={{marginBottom:"20px"}}>
                <div className={`participant-item`} >
                    <div className="participant-item__wrapper">
                        <div className={"participant-item__inner"}>
                            <div className="participant-item__id">ID:</div>
                            <div className="participant-item__avatar"></div>
                            <div className="participant-item__user-name">Имя водителя</div>
                            <div className="participant-item__name">Номер авто</div>
                            <div className="participant-item__money">Общий доход</div>
                            <div className="participant-item__money">Баланс водителя</div>
                            <div className="participant-item__money">Доход автомобиля</div>
                            <div className="participant-item__money">Трекер</div>
                            <div className="participant-item__money">Коэффициент</div>

                            {edit ? <div className="participant-item__inputs">
                                Редактировать
                            </div> : <div className="participant-item__money">Расходы авто</div>}
                            <div className="participant-item__date">Дата</div>
                        </div>
                    </div>


                </div>

            </div>

            {items.length > 0 ?
                items.map(item=> {
                    return <div key={item.id} className="participant-list__item"><ParticipantItem
                        isDelete={isDelete}
                        onDelete={()=>onDelete(item.id)}
                        isCheck={isCheck}
                        numbersList={numbersList}
                        isSelected={false}
                        id={item.id.toString()}
                        onSelect={(e)=>{}}
                        userName={`${item.first_name} ${item.last_name}`}
                        name={item.licencePlate as string}
                        earnedCar={`${item.incomeCar}`}
                        edit={edit}
                        balanceDriver={` ${item.balanceDriver} грн.`}
                        onSave={(values)=>onSave(item.id,values)}
                        vehicleMoney={item.vehicleMoney.toString()}
                        tracker={item.tracker.toString()}

                      //  status={item.status ? Number(item.status) : null}
                      //  type={item.type ? item.type: null} applyMultiplierMoney
                        money={`${(item.total/100).toFixed(2)}`}
                        date={`${getTimeDate(dateFrom*1000)} - ${getTimeDate(dateTo*1000) }`}
                    /></div>
                })
                : <Placeholder header={'Ups!'} message={'Users not found'} />
            }
            {totalCount > 0 &&
                <div className="participant-list__pagination">
                    <Pagination pageUrl={pageUrl} currentPage={currentPage} totalItems={totalCount} itemsPerPage={perPage} onPageChange={onChangePagination}/>
                </div>
            }
        </div>
    );
};

export default ParticipantsList;